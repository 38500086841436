import styled from 'styled-components'

export const GrilleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media(min-width: 570px) {
    align-items: flex-end;
  }
`
