/* eslint-disable max-len */
import React from 'react'

import TshirtTable from '../Tshirt/Grille/TshirtTable'
import { TablesContainer, SingleLine } from '../Tshirt/Grille/styles'
import { GrilleWrapper } from './styles'

import {
  biColor,
  biColorSpped,
  normalTable,
  speedTable,
} from './data'

import { genTableData } from '../Tshirt/Grille/helpers'

const Grille = () => (
  <GrilleWrapper>
    <TablesContainer column>
      <TshirtTable
        lines={genTableData(normalTable)}
      />
      <SingleLine>
        {`Impression recto + verso 1 couleur ou une face 2 couleurs : +${biColor}€/unité`}
      </SingleLine>
    </TablesContainer>
    <TablesContainer column>
      <TshirtTable
        title="Impression rapide en deux semaines (selon les disponibilités)"
        lines={genTableData(speedTable)}
      />
      <SingleLine>
        {`Impression recto + verso 1 couleur ou une face 2 couleurs : +${biColorSpped}€/unité`}
      </SingleLine>
    </TablesContainer>
  </GrilleWrapper>
)

export default Grille
