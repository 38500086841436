import { getPrice } from '../Tshirt/helpers'

import {
  normalTable, speedTable, biColor, biColorSpped,
} from './data'

export const countTotal = ({ quantity, isBiColor, speed }) => {
  const table = speed ? speedTable : normalTable

  return getPrice({
    table,
    quantity,
    isBiColor,
    speed,
    biColorPrice: biColor,
    biColorSppedPrice: biColorSpped,
  })
}

export const genDataForEmailDevi = ({
  quantity, isBiColor, speed,
}) => {
  const data = [
    {
      label: 'Quantité',
      value: quantity,
    },
    {
      label: 'Recto + verso 1 couleur ou une face 2 couleurs',
      value: isBiColor,
    },
    {
      label: 'Impression rapide en 2 semaines',
      value: speed,
    },
  ]

  return data
}
